<template>
  <main class="kb-main" id="kb-myclass-hrdcloud">
    <lxp-main-header :show-back="true" :show-custom-title="true" :title="`연수알리미`">
      <template v-slot:action>
        <!-- main-header > header-tab -->
        <div class="header-tab">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link"
                   :class="{ 'is-active' : selectedTab === 'recent' }"
                   @click="selectedTab = 'recent'" >
                  <span class="kb-nav-text">최근 3개월</span>
                </a>
              </li>
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link dropdown-toggle" data-toggle="dropdown"
                   :class="{ 'is-active' : selectedTab === 'self' }"
                   @click="selectedTab = 'self'">
                  <span class="kb-nav-text dropdown-toggle-text">직접설정</span><i class="icon-arrow"></i>
                  <CommonDatePicker v-if="selectedTab === 'self'"/>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </template>
    </lxp-main-header>
    <!-- 최근 3개월 -->
    <div class="main-content min-component">
      <div class="reminder-list-container">
        <div class="list-top">
          <!--        <div v-if="paging.totalCount > 0" class="top-column ps-6">-->
          <!--          <p class="title text-muted">총 {{ paging.totalCount }}건</p>-->
          <!--        </div>-->
          <div class="top-column">
<!--            <form @submit.prevent>-->
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" v-model="search.notiTitle" @keyup.enter="clickSearch" class="kb-form-search-input" placeholder="알리미 검색" />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                </div>
              </div>
<!--            </form>-->
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <article class="board-row">
                <router-link :to="{name: 'TrainNotiView', params: {trainNotiSn: item.notiTrsmSn}}" class="board-link"></router-link>
<!--                <div class="board-column column-index"><em class="text">일반</em></div>-->
                <div class="board-column column-category"><span class="text text-muted">{{ item.notiSbjt }}</span></div>
                <div class="board-column column-file">&nbsp;</div>
                <div class="board-column column-title"><span class="title">{{item.notiTitle}}</span></div>
                <div class="board-column column-date"><span class="text">{{item.notiTrsmCmptnDt}} 발송</span></div>
                <div class="board-column column-date"><span class="text">{{item.notiRcptnDt}} {{ item.notiRcptnDt === null ? '' : '열람' }}</span></div>
              </article>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>
    <!-- //최근 3개월 -->
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {reactive, ref, watch} from 'vue';
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {ACT_GET_NOTI_TRSM_LIST} from "@/store/modules/notification/notification";
import CommonDatePicker from "@/components/common/CommonDatePicker";

export default {
  name: 'TrainNotification',
  components: {CommonDatePicker, LxpMainHeader, CommonPaginationFront},
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route, 5));
    const items = ref([]);
    const selectedTab = ref('recent')

    const search = reactive({
      notiTitle:'',
      bgngYmd: null,
      endYmd: null,
    });

    const getTrainNotiList = () => {
      store.dispatch(`notification/${ACT_GET_NOTI_TRSM_LIST}`, {
        notiTitle: search.notiTitle,
        threeMonth: (selectedTab.value === 'recent' ? 'Y' : ''),
        bgngYmd: search.bgngYmd,
        endYmd: search.endYmd,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res).map(x => ({
            ...x,
            notiTrsmCmptnDt: timestampToDateFormat(x.notiTrsmCmptnDt, 'yyyy-MM-dd, hh:mm'),
            notiRcptnDt: timestampToDateFormat(x.notiRcptnDt, 'yyyy-MM-dd, hh:mm'),
          }));
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getTrainNotiList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (search.notiTitle) query.notiTitle = search.notiTitle;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 3);
    };

    watch(() => route.query, () => {
      if (route.name === 'TrainNotification') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }
        getTrainNotiList();
      }
    });

    return{
      items,
      paging,
      search,
      selectedTab,

      pagingFunc,
      clickSearch,
    }
  }
};
</script>

<style scoped>

</style>